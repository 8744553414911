
.swiper {
    width: 100%;
    height: 100%;
    display: flex;
    flex-direction: column;
    position: relative;
    opacity: 0;
    margin-top: 6%;
    transition: opacity 0.1s ease-in-out;
}

.loaded.swiper {
    opacity: 1;
}

.swiper--status {
    position: absolute;
    top: 25%;
    margin-top: -30px;
    z-index: 100;
    width: 100%;
    display: flex;
    justify-content: center;
    pointer-events: none;
}
.swiper-name{

}
.swiper--status span {
    opacity: 0;
    max-width: 60%;
    transition: all 0.4s ease-in-out;
    position: absolute;
    pointer-events: none;
    display: inline-block;

}
.swiper--status span img{
    max-width: 60%;
}
.fa-heart {
    pointer-events: none;
}

.fa-remove {
    pointer-events: none;
}

.swiper--cards {
    flex-grow: 1;
    position: relative;
    text-align: center;
    display: flex;
    justify-content: center;
    align-items: center;
    z-index: 1;
}

.swiper--card {
    display: inline-block;
    width: 90vw;
    max-width: 85%;
    height: 100%;
    border-radius: 8px;
    opacity: 1;
    position: absolute;
    will-change: transform;
    transition: all 0.3s ease-in-out;
    display: flex;
    justify-content: center;
    cursor: grab;
}

.swiper--JastCard {
    display: inline-block;
    width: 90vw;
    max-width: 100%;
    pointer-events: none;
    height: 95%;
    background: rgb(255,250,246);
    border-radius: 8px;
    opacity: 1;
    will-change: transform;
    transition: all 0.3s ease-in-out;
    display: flex;
    justify-content: center;
    cursor: grab;
    border-radius: 15px;
    outline: 0.7px solid rgb(233 220 226);
    box-shadow: 0px 4.7px 4.6px 0.7px rgb(46 0 94 / 24%), inset 0px -1.3px 0.3px 0px rgb(98 19 154 / 11%), inset -0.1px -3.3px 0.3px 0px rgb(98 19 154 / 11%);
}
.moving.swiper--card {
    transition: none;
    cursor: -webkit-grabbing;
    cursor: -moz-grabbing;
    cursor: grabbing;
}


.swiper--card h3 {
    margin-top: 32px;
    font-size: 32px;
    padding: 0 16px;
    pointer-events: none;
}

.swiper--card p {
    margin-top: 24px;
    font-size: 20px;
    padding: 0 16px;
    pointer-events: none;
}

.swiper--buttons {
    flex: 0 0 98px;
    text-align: center;
    justify-content: center;
    align-items: center;
    display: flex;
    width: 80%;
    margin: auto;
    margin-bottom: 13%;
}

.swiper--buttons button {
    border-radius: 50%;
    line-height: 60px;
    border: 0;
    background: transparent;
    display: flex;
    flex: 1;
    margin:20px 8px 0px 8px;
    cursor: pointer;
    position: relative;
    justify-content: center;
}

.swiper--buttons button:hover {
  transform: scale(1.1);

}
.swiper--buttons button:focus {
    outline: 0;
}

#love.superLike{
    z-index: 1000;

}
#love.superLike .fa-heart{
    z-index: 1000;
    display: flex;
    align-items: center;
    justify-content: center;
}
#love.superLike .likeHeard{
    background: white;
    border-radius: 100%;
    box-shadow: 0px -3px 7px 10px white;
}
.swiper--buttons button img{
    vertical-align: middle;
    max-width: 50%;

}
@media (max-width: 756px) {
    .swiper--buttons button img{
        vertical-align: middle;

        max-width: 75%;

}
    .swiper--buttons button {

        margin:-6px 8px 0px 8px;

    }
}
.removed{
   /* opacity: 0; */
    z-index: 100 !important;
    transition: all 1s ease-in-out;
}

.fa-heart {
    color: #FFACE4;
}

.fa-remove {
    color: #CDD6DD;
}
.swiper-imgbg{
    position: relative;
    pointer-events: none;
    height: 100%;
}
.swiper-imgbg .swiper-img-bg{
    max-width: 100%;
    position: relative;
    left: 0;
    right: 0;
    height: 100%;
}
.swiper-avatar{
    z-index: 14;
    position: absolute;
    width: 100%;
    pointer-events: none;
    display: flex;
    flex: 1;
    height: 93.2%;
    overflow: hidden;
    flex-direction: column;
    top: 0;
    align-items: center;

}

.swiper-name{

}